@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&family=Roboto&family=Roboto+Mono:ital,wght@0,200;0,300;0,400;1,200&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Dongle:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@900&family=Patrick+Hand&family=Roboto&family=Roboto+Mono:ital,wght@0,200;0,300;0,400;1,200&display=swap");

/*font-family: 'Nunito', sans-serif;
font-family: 'Patrick Hand', cursive;
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Mono', monospace;*/

.carousel-outside {
  display: flex;
  justify-content: center;
  background-color: #010036;
  padding: 15px;
}

.carousel-container {
  max-height: 650px;
  background-color: white;
}

.carousel-image {
  max-height: 600px;
  max-width: 100%;
  height: auto;
}

.button-primary {
  background-color: #5f93da;
  border-color: #5f93da;
}

.button-success {
  background-color: #8fc36d;
  border-color: #8fc36d;
}

.button-caution {
  background-color: #ffaa1b;
  border-color: #ffaa1b;
}

.button-danger {
  background-color: #de4756;
  border-color: #de4756;
}

.button-pink {
  background-color: #ff7fa6;
  border-color: #ff7fa6;
}

.border-pink {
  border: 3px solid #ff7fa6;
  border-radius: 5px;
}

.border-blue {
  border: 3px solid #5f93da;
  border-radius: 5px;
}

.border-yellow {
  border: 3px solid #ffaa1b;
  border-radius: 5px;
}

.border-green {
  border: 3px solid #8fc36d;
  border-radius: 5px;
}

.border-silver {
  border: 3px solid #c0c0c0;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.border-gold {
  border: 3px solid #ffd700;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.border-crystal {
  border: 3px solid #a7d8de;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.border-ruby {
  border: 3px solid #e0115f;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.border-sapphire {
  border: 3px solid #0f52ba;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.border-emerald {
  border: 3px solid #50c878;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.dongleText {
  font-family: "Dongle", sans-serif;
  font-size: larger;
  font-weight: bold;
}
/* Creating standard variables for use through out CSS*/
:root {
  --errorColor: red;
}

.lightRed {
  background-color: #FF8484 !important;
}

.inventoryTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.inventoryTable td, th {
  display: table-cell;
  border-color: #b2b2b2;
  border-style: solid;
  vertical-align: inherit;
  unicode-bidi: isolate;
  border-width: 1px;
  padding: .7rem;
}

.inventoryTable table {
  width: 100%;
}

.orderTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.orderTable td, th {
  display: table-cell;
  border-color: #b2b2b2;
  border-style: solid;
  vertical-align:  top !important;
  unicode-bidi: isolate;
  border-width: 1px;
  padding: .7rem;
}

.orderTable table {
  width: 100%;
}

.userTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.userTable td, th {
  display: table-cell;
  border-color: #b2b2b2;
  border-style: solid;
  vertical-align:  top !important;
  unicode-bidi: isolate;
  border-width: 1px;
  padding: .7rem;
}

.userTable table {
  width: 100%;
}

* {
  font-family: "Roboto", sans-serif;
}

.header {
  font-family: "Nunito", sans-serif;
  text-transform: uppercase;
  font-size: 30pt;
}

.subheader {
  font-family: "Nunito", sans-serif;
  text-transform: uppercase;
  font-size: 24pt;
}

.subheaderNoUpper {
  font-family: "Nunito", sans-serif;
  font-size: 24pt;
}

.cardHeader {
  font-family: "Nunito", sans-serif;
  font-size: 13pt;
  letter-spacing: -1px;
}

.subheaderRewards {
  font-family: "Nunito", sans-serif;
  text-transform: uppercase;
  font-size: 18pt;
}

body {
  overflow-y: scroll;
  min-height: 100vh;
}

h1 {
  font-family: "Nunito Black", sans-serif;
  text-transform: uppercase;
  font-size: 16pt;
  font-weight: bold;
}

h2 {
  font-family: "Nunito Black", sans-serif;
  text-transform: uppercase;
  font-size: 14pt;
  font-weight: bold;
}

h3 {
  font-family: "Nunito Black", sans-serif;
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: bold;
}

h4 {
  font-family: "Nunito Black", sans-serif;
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: bold;
}

.inventoryTableRow input {
  margin-bottom: 12px;
}



.inventoryDivider {
  padding: 8px;
  margin-bottom: 12px;
  border: 1.5px solid black;
}

.conditionHeader {
  margin-bottom: 12px;
  border-bottom: gray 1px solid;
  text-align: center;
}

.inventoryTableRow h4 {
  padding: 0;
}

/* NavBar Style*/

.navbar {
  font-family: "Nunito", sans-serif;
  background-color: #ffb6ce;
  justify-content: center;
}

.navHeader {
  text-transform: uppercase;
  font-size: 14pt;
  font-weight: bolder;
}

.navSubHeader {
  font-family: "Nunito Black", sans-serif;
  text-transform: uppercase;
  font-size: 12pt;
  font-weight: bold;
}

.logo {
  width: 165px;
  margin-bottom: 10px;
}

.logoMobile {
  height: 40px;
}

.typeahead-form-group {
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;
  width: 85%;
  z-index: 990;
  margin: 10px 10px 5px 10px;
}

.typeahead-list-group {
  position: absolute;
  width: 100%;
  overflow-y: scroll;
  max-height: 13rem;
}

.typeahead-list-group-item_active{
  background-color: #FFD051;
}

/* Footer Style */

footer {
  max-height: 80vh;
  z-index: 2;
  background-color: #010036;
  max-width: 100%;
  display: flex;
  position: sticky;
}

.cart {
  margin-bottom: 3vh;
}

.cartItems {
  height: 80vh;
  overflow-y: scroll;

}

.cartItemsCheckout {
  height: 85vh;
  overflow-y: scroll;

}


.verticalAlign {
  vertical-align: bottom;
}

.cardInformation {
  margin-bottom: 10vh;
}

.socialBar {
  margin: auto;
}

.section {
  display: grid;
  justify-content: center;
  align-content: center;
}

.terms {
  font-size: 10.5px;
}

.cartPreview {
  position: absolute;
  width: 500px;
  height: 400px;
  top: 8.3rem;
  right: 0.8rem;
  padding: 5px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid grey;
}
.closeButton {
  position: relative;
  display: inline-block;
  left: 10rem;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.cartPreview::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  top: -1.9rem;
  right: 4.6rem;
  transform: rotate(45deg);
  background-color: white;
  border-top: 1px solid grey;
  border-left: 1px solid grey;
}

/* Carousel Style*/

.carousel-outside {
  background-color: "#010036";
  border: 2px solid;
  border-radius: 5px;
  max-height: 720px;
  max-width: 1753px;
}

.carousel-inner {
  max-height: 650px;
  max-width: 1753px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
  margin-bottom: 70px;
}

.carousel-indicators [data-bs-target] {
  width: 15px; /* change width */
  height: 15px; /* change height */
  border-radius: 100%; /* add border-radius */
  margin-right: 15px;
}

.carousel-indicators {
  bottom: -4.3rem;
}

.columnHomePageBox {
  border: 2px solid;
  border-radius: 10px;
  height: 190px;
  max-width: 550px;
  width: 100%;
}

.star {
  height: 40px;
  width: auto;
  display: inline-flex;
}

/* Cards Page Style*/
.cards {
  margin-left: 10px;
  margin-right: 10px;
}

/* New Users Form Page Style*/
.newUserForm {
  margin-left: 35px;
  margin-right: 35px;
}

/* Login Users Form Page Style*/
.loginForm {
  margin-left: 35px;
  margin-right: 35px;
}

.accordionItem {
  min-width: 22%;
}

/* Cards Template styling */

/* Filter Styles */
.filterItems {
  min-width: 22%;
}

.formCheck {
  font-size: large;
}

/*Error form handling */
.errorform {
  border: 1px solid var(--errorColor);
}

.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--errorColor);
  color: var(--errorColor);
  border-radius: 4px;
  margin: 20px 0;
}

.cardSection {
  width: 78%;
}
.filtersSection {
  width: 22%;
}

.cardImg {
  aspect-ratio: 2/3 !important;
}

.price {
  font-size: x-large;
}

.outOfStock {
  font-size: x-large;
}

.help {
  overflow: hidden;
}

/* css for Breadcrumb */
.breadcrumbs {
  border: 1px solid #cbd2d9;
  border-radius: 0.3rem;
  display: inline-flex;
  overflow: hidden;
  margin-bottom: 0.25vh;
}

.breadcrumbs__item {
  background: #fff;
  color: #333;
  outline: none;
  padding: 0.75em 0.75em 0.75em 1.25em;
  position: relative;
  text-decoration: none;
  transition: background 0.2s linear;
}

.breadcrumbs__item:hover:after,
.breadcrumbs__item:hover {
  background: #edf1f5;
  cursor: pointer;
}

.breadcrumbs__item:focus:after,
.breadcrumbs__item:focus,
.breadcrumbs__item.is-active:focus {
  background: #323f4a;
  color: #fff;
}

.breadcrumbs__item:after,
.breadcrumbs__item:before {
  background: white;
  bottom: 0;
  clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  transition: background 0.2s linear;
  width: 1em;
  z-index: 1;
}

.breadcrumbs__item:before {
  background: #cbd2d9;
  margin-left: 1px;
}

.breadcrumbs__item:last-child {
  border-right: none;
}

.breadcrumbs__item.is-active {
  background: #edf1f5;
}

.stripeForm #root {
  display: flex;
  align-items: center;
}

.stripeForm body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 75vh;
  width: 75vw;
}

.stripeForm form {
  width: 30vw;
  min-width: 300px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.stripeForm #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.stripeForm #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripeForm button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripeForm button:hover {
  filter: contrast(115%);
}

.stripeForm button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.stripeForm .spinner,
.stripeForm .spinner:before,
.stripeForm .spinner:after {
  border-radius: 50%;
}

.stripeForm .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.stripeForm .spinner:before,
.stripeForm .spinner:after {
  position: absolute;
  content: "";
}

.stripeForm .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.stripeForm .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.CookieConsent {
  background-color: #de4756 !important;
}

.CookieConsent #rcc-deline-button {
  background-color: #323f4a !important;
}

.hoverDiv:hover:after,
.hoverDiv:hover {
  cursor: pointer;
}

.tableAlignMiddle {
  vertical-align: middle;
  text-align: center;
}

.ccForm label{
  width: 100%;
}

.trade_function_prices {
  text-align: center;
  justify-content: center;
  margin: 2;
  font-family: "Nunito Black", sans-serif;
  text-transform: uppercase;
  font-size: 16pt;
  font-weight: bold;
}

.tradeFunctionDivider {
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  border: 1.5px solid black;
}

.holoBG{
  background-color: #FFD051;
}

.nonHoloBG{
  background-color: #c0c0c0;
}

.revHoloBG{
  background-color: #ce187c;
}

.urBG{
  background-color: #a264ee;
}

.feBG{
  background-color: #1bce18;
}

.ulBG{
  background-color: #18b6ce;
}

.shBG{
  background-color: #ceb918;
}

.promoBG{
  background-color: #d1e457;
}